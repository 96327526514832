@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes circular {
  0% {
    transform: rotate(0deg) translateY(5px) rotate(0deg);
  }

  100% {
    transform: rotate(360deg) translateY(5px) rotate(-360deg);
  }
}

@keyframes zoomFadeIn {
  from {
    opacity: 0;
    scale: 0.1;
  }

  to {
    opacity: 1;
    scale: 1;
  }
}

.circular {
  animation: circular 10s linear infinite;
}


.fadeIn {
  animation: fadeIn 200ms ease forwards;
}

.fadeOut {
  animation: fadeOut 200ms ease forwards;
}

.zoomFadeIn {
  animation: zoomFadeIn 500ms ease forwards 100ms;
}