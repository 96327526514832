#main {
    padding: 170px var(--padding-inline) 100px var(--padding-inline);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 70px;
    /* min-height: 100vh; */
    max-width: 1440px;
    margin-inline: auto;
    color: var(--keel-navy);
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
}

.content ul {
    margin-left: 20px;
}