#contact_form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    color: var(--keel-navy);
}

#contact_form>label {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 5px;
    width: 100%;
}

#contact_form>label>strong {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
}

.input_container.has_error>.input {
    border: 1px solid var(--keel-red);
}

.error_text {
    color: var(--keel-red);
    display: none;
}

.input_container.has_error>.error_text {
    display: inline;
}

.input {
    border: 1px solid var(--keel-navy);
    border-radius: 8px;
    padding: 12px 16px;
    line-height: 20px;
    font-size: 16px;
    width: 100%;
    color: var(--keel-navy);
}

.input::placeholder {
    color: #002C614D
}

select.input:invalid {
    color: #002C614D;
}

textarea.input {
    resize: none;
    font-family: 'Inter', sans-serif;
}

#submit {
    border: 0;
    padding: 10px 20px;
    font-size: 12px;
    line-height: 20px;
    cursor: pointer;
}

.submission_info {
    display: flex;
    flex-direction: column;
    line-height: 20px;
    gap: 15px;
}

.submission_info a {
    color: var(--keel-navy);
}

.submission_info strong {
    line-height: 24px;
}