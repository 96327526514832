#main {
    background-color: var(--keel-light-grey);
    padding: 170px var(--padding-inline) 100px var(--padding-inline);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 70px;
}

#title {
    color: var(--keel-navy);
    font-weight: 600;
    font-size: 60px;
    line-height: 68px;
}

#policy_container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 40px;
}

#policy_container h2 {
    font-weight: 600;
    margin-bottom: 10px;
}

#policy_container h3 {
    font-weight: 600;
    margin-bottom: 10px;
}

#policy_container p {

    margin-top: 10px;
}

#policy_container *:is(ul, ol) {
    margin-top: 10px;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 5px;
}

#policy_container * {
    color: var(--keel-navy);
}