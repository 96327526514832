.values_grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 48px 58px;
}

.value {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 18px;
}

.value_content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;

    color: var(--keel-navy);
}

.value_content strong {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
}

.value_content p {
    line-height: 24px;
}

.value_number {
    color: var(--keel-orange);
    font-size: 24px;
    line-height: 29px;
}

@media (max-width: 830px) {

    /* small */
    .values_grid {
        gap: 20px;
    }

}

@media (max-width: 570px) {

    /* x small */
    .values_grid {
        grid-template-columns: 1fr;
        grid-template-rows: none;
        grid-auto-flow: row !important;
    }
}