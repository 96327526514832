.arrow_subtitle {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
}

.text {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: white;
}

.dark .text {
    color: var(--keel-navy);
}

.arrow_image {
    width: 24px;
    height: 24px;
}