.solution_component {
    color: var(--keel-navy);
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 80px;
    position: relative;
    z-index: 2;
}

.left {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    padding-top: 40px;

}

.solution_component.reversed .left {
    order: 1;
}

.heading {
    font-weight: 600;
    font-size: 32px;
    line-height: 32px;
    margin-bottom: 10px;
}

.button {
    padding: 10px 20px;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
}

.description {
    margin-bottom: 20px;
    line-height: 24px;
}


.graphic_img {
    max-width: 100%;
    height: 400px;
    object-fit: contain;
    /* object-position: top center; */
}

@media (max-width: 830px) {

    /* small */

    .right>* {
        margin-inline: 40px;
    }

    .solution_component {
        grid-template-columns: 1fr;
        justify-items: center;
        max-width: 100%;
        margin-inline: auto;
        gap: 0;
    }

    .graphic_img {
        max-width: 100%;
        width: 100%;
    }

    .solution_component .left {
        /* undo reversing */
        order: 1;
    }
}