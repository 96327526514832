#hero_images_overflow_container {
    overflow: hidden;
    max-width: min(1440px, calc(100vw - 15px));
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* margin-top: 110px; */
}


#hero_images {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    min-width: min(110vw, 1440px);
}

#hero_images img {
    width: 100%;
    min-height: 300px;
    max-height: 500px;
    object-fit: cover;
    border-radius: 16px;
}