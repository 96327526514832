.advisor_card {
    background-color: rgba(255, 255, 255, 0.5);

    padding: 22px 24px;
    width: 100%;
    border-radius: 16px;
    border: 1px solid var(--keel-white);

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
}

.right {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 4px;
    flex-grow: 1;
}

.left img {
    width: 56px;
    height: 56px;
}

.top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 0px;
    width: 100%;
}

.title {
    color: var(--keel-orange);
    font-weight: 500;
    line-height: 20px;
}

.name {
    font-weight: 700;
    color: var(--keel-navy);
    font-size: 20px;
    line-height: 24px;
}