#content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
}

#top,
#left,
#right {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 20px;
}

#bottom {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
}

#heading {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 0px;
}