.tile {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
    align-items: center;
    border-radius: 16px;

    background-color: var(--keel-light-grey);
    /* text-align: center; */
    padding: 20px;
    text-decoration: none;

    font-size: 18px;

}