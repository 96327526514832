.color_banner {
    color: var(--keel-white);
    padding: 120px 260px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
    text-align: center;
}

.color_banner h1 {
    font-weight: 600;
    font-size: 40px;
    line-height: 44px;
}

.link_button {
    /* border-color: var(--keel-navy); */
    /* color: var(--keel-navy); */
    /* background-color: var(--keel-white); */
    font-weight: 500;
    line-height: 20px;
    padding: 12px 24px;
}

@media (max-width: 1370px) {

    /* medium */
    .color_banner {
        padding-inline: 70px;
    }
}

@media (max-width: 830px) {
    /* small */

    .color_banner {
        padding-inline: 20px;
    }

    .color_banner h1 {
        font-size: 30px;
        line-height: 34px;
    }
}

@media (max-width: 570px) {
    /* x small */

    .color_banner h1 {
        font-size: 24px;
        line-height: 28px;
    }
}