.modal_backdrop {
    display: none;
}

.modal_backdrop.show_modal {
    position: fixed;
    inset: 0;
    width: 100%;
    height: 100%;
    background-color: #0012294D;
    z-index: 30;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal {
    border-radius: 16px;
    background-color: #ffffff;
    box-shadow: 25px 25px 50px #002C611A;
    padding: 20px 16px;
    max-height: 100vh;
    overflow: scroll;

    scrollbar-width: none;
}

.modal::-webkit-scrollbar {
    display: none;
    /* Chrome, Safari, and Edge */
}

.content_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0px;
}

.close_button {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: var(--keel-light-grey);
    cursor: pointer;
    border: none;
}