.tabbed_carousel {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 80px;
    width: 100%;

}

.tabs_container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;
    max-width: 30vw;
}

.tab {
    width: 420px;
    max-width: 100%;
    padding: 16px 20px;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;
    color: var(--keel-navy);
    border-radius: 16px;
    background-color: transparent;
    border: 1px solid var(--keel-navy);
    cursor: pointer;
}

.tab_index {
    font-size: 32px;
    line-height: 24px;
    font-weight: 300;
    box-shadow: transparent 0px 0px 0px;
}

.tab_label {
    font-size: 28px;
    line-height: 32px;
    font-weight: 600;
}

.tab.active {
    --webkit-backdrop-filter: blur(12px);
    backdrop-filter: blur(12px);
    box-shadow: 25px 25px 50px 0px #002C611A;
    border: 1px solid var(--keel-white);
    background: #FFFFFF66;
}

.tab.active .tab_index {
    color: var(--keel-orange);

}

.current_content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 30px;
}

.current_content>*:not(.description) {
    /* change this when adding image overlays */
    /* max-width: 110%; */
    /* max-width: 100%; */
    margin-inline: 40px;
    /* height: 300px; */
    /* zoom: 1.1; */
    /* filter: drop-shadow(-7px 7px 13.5px #1D32601A); */
}

.current_content>.description {
    line-height: 24px;
    color: var(--keel-navy);
    white-space: pre-wrap;
}

@media (max-width: 1370px) {
    /* medium */

    .tabbed_carousel {
        gap: 40px;
    }

    .tab {
        width: 300px;
        gap: 20px;
    }

    .tab_index {
        font-size: 28px;
    }

    .tab_label {
        font-size: 24px;
    }

    .current_content>img {
        max-width: 100%;
    }
}

@media (max-width: 830px) {

    /* small */
    .tabbed_carousel {
        flex-direction: column;
        gap: 40px;
    }

    .tabs_container {
        width: 100%;
        align-items: center;
        flex-direction: row;
    }

    .tab {
        height: 138px;
        gap: 10px;
        justify-content: space-between;
    }


    .tab_label {
        font-size: 16px;
        line-height: 18px;
    }

    .current_content>img {
        /* change this when adding image overlays */
        object-position: center;
    }


}

@media (max-width: 570px) {
    /* x small */

    .tabs_container {
        gap: 10px;
    }

    .tab {
        padding: 10px;
        height: 100px;
    }

    .tab_label {
        font-size: 14px;
        line-height: 16px;
        /* word-break: break-all; */
    }

    .tab_index {
        font-size: 18px;
    }
}