#section {
    background-color: white;
    padding-block: 135px 190px;
    display: flex;


}

#container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 35px;
    max-width: 1440px;
    margin-inline: auto;
    padding-inline: var(--padding-inline);
}

#text {
    align-self: center;
    color: var(--keel-navy);

    /* min-width: 220px; */
    font-weight: 600;
    font-size: 40px;
    line-height: 44px;
    padding-inline: var(--padding-inline);

}

#text span {
    color: var(--keel-orange);
}