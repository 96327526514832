#main {
    background-color: var(--keel-light-grey);
}

#hero {
    padding-block: 194px 130px;
    color: white;

    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    align-items: flex-start;
    gap: 0px;
    /* min-height: 100vh; */
    position: relative;
    z-index: 2;
}


#hero_top {
    padding-inline: var(--padding-inline);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;

}


#hero_top h1 em {
    font-weight: 300;
}

#hero_top p {
    /* min-width: 495px; */
    font-size: 20px;
    line-height: 28px;
}

#modal_content_container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 40px;
}

#hero_learn_more {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    padding: 10px 20px;
    margin-top: 25px;
    border: none;
    cursor: pointer;
}

.hero_bottom {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: calc(100vw - 15px);
    height: max-content;
    translate: 0 60%;
    /* max-width: 100vw;
    overflow: hidden; */
}

.hero_image_cards_container {
    display: grid;
    grid-template-columns: 2fr 1.5fr 3fr;
    min-width: calc(100vw + 90px);
    gap: 25px;
    overflow: visible;
}

.hero_image_card {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 16px;
    height: 440px;

}

/* #content>section {
    max-width: 1440px;
    margin-inline: auto;
} */

#biomarkers {
    padding-top: 100px;
    background: linear-gradient(28.5deg, #060E16 18.39%, #142751 116.01%);
    background-repeat: no-repeat;
    background-position: top center;
    overflow: hidden;
    position: relative;
    z-index: 1;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 0px;
}

#biomarkers_text_content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 0px;
    color: white;
    position: absolute;
    top: 25%;
    left: 50%;
    translate: -50%;
    width: 100%;
    padding-inline: var(--padding-inline);
}

.biomarkers_subheading {
    font-size: 24px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    position: relative;
}

.biomarkers_subheading em {
    font-weight: 300;
}

.biomarkers_subheading#real {
    position: absolute;
    left: 50%;
    color: white;
    translate: -50%;
    bottom: 160px;
}

#biomarkers_graphic {
    /* margin-left: -140px;
    margin-right: -90px;
    position: relative;
    top: -20px; */
    /* z-index: 2; */

    max-height: 900px;
    top: 0;

}



#timeline {
    position: relative;
    background-color: white;
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-inline: var(--padding-inline);
}

.timeline_top {
    color: var(--keel-navy);
    position: relative;
    padding-block: 100px;
    position: sticky;
    top: 0;
    max-height: max-content;
    top: 0;
    background-color: white;
    z-index: 4;
    padding-right: 150px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.timeline_top h2 {
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 40px;
    line-height: 44px;
}

.timeline_top p {
    line-height: 24px;
}

.timeline_bottom {
    position: relative;
    max-width: 1440px;
    margin-inline: auto;
}

#platforms {
    padding-block: 120px 240px;
    padding-inline: var(--padding-inline);

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 130px;

    color: var(--keel-navy);

}

.platforms_right h2 {
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 40px;
    line-height: 44px;
}

.platforms_right p {
    line-height: 24px;
}

.platforms_left {
    display: grid;
    grid-template-columns: repeat(2, 280px);
    gap: 24px;
}

.platform_card {
    color: var(--keel-navy);
}

.platform_card_text strong {
    font-weight: 700;
    font-size: 28px;
    line-height: 32px;
}

.platform_card_text p {
    line-height: 20px;
    /* max-width: 170px; */
    margin-top: 10px;
}

.platform_card {
    padding: 30px 25px 15px 25px;
    border-radius: 16px;
    border: 1px solid var(--keel-navy);

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 90px;
    max-width: 280px;
}

.platform_card.active {
    padding: 40px 47px 18px 37px;
    border-radius: 16px;
    border: 2px solid var(--keel-white);
    background-color: var(--keel-white);

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 70px;
    max-width: 280px;

    backdrop-filter: blur(20px);
    --webkit-backdrop-filter: blur(20px);
    box-shadow: 25px 35px 50px 0px #001D3F33;

}

.platform_card img {
    width: 180px;
}

#what_we_value {
    padding-inline: var(--padding-inline);
    padding-block: 100px;
}


@media (max-width: 1370px) {

    /* medium */
    #hero_top {
        gap: 20px;
    }

    .timeline_top {
        padding-right: 80px;
    }

    #platforms {
        flex-direction: column-reverse;
        gap: 40px;
    }

    .platforms_right {
        text-align: center;
    }
}

@media (max-width: 830px) {
    /* small */

    #hero_top {
        grid-template-columns: 1fr;
    }

    .hero_bottom {
        translate: 0 100%;
    }

    .hero_image_cards_container {
        gap: 12px;
    }

    .hero_image_card {
        height: 250px;

    }

    #biomarkers {
        padding-top: 250px;
        padding-bottom: 100px;
        max-width: 100vw;
    }

    #biomarkers_text_content {
        top: 15%;
    }


    #biomarkers_graphic {
        margin-top: 100px;
    }

    .timeline_top {
        padding-right: 60px;
    }

    .platforms_left {
        grid-template-columns: 1fr 1fr;
    }

    .platform_card {
        width: 100%;
    }

    .platform_card {
        padding: 25px 15px 10px 15px;
    }

    .platform_card.active {
        padding: 30px 37px 12px 27px;
    }

}

@media (max-width: 570px) {
    /* x small */

    /* #hero_top h1 {
        font-size: 40px;
        line-height: 44px;
    } */

    #hero_top p {
        font-size: 16px;
        line-height: 20px;
    }

    #biomarkers_graphic {
        margin-top: 200px;
    }

    #timeline {
        grid-template-columns: 1fr;
    }

    .timeline_top {
        padding-right: 20px;
        position: relative;
    }


    #platforms {
        padding-block: 60px 100px;
    }

    .hero_image_cards_container {
        gap: 8px;
    }

    .hero_image_card {
        height: 200px;

    }

    .platforms_left {
        grid-template-columns: 1fr;
        width: 100%;
        gap: 35px;
    }

    .platform_card,
    .platform_card.active {
        max-width: none;
        width: 100%;
        flex-direction: row;
        padding-top: 15px;
    }

    .platform_card img {
        width: 100px;
    }

    .values_grid {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(4, 1fr);
    }

    #modal_graphic {
        max-height: 50vh;
    }

    #modal_content_container {
        flex-direction: column;
    }
}