.section_heading {
    color: var(--keel-navy);
    text-align: center;
    margin-bottom: 40px;
    max-width: 1030px;
    margin-inline: auto;

    display: flex;
    flex-direction: column;
    /* justify-content: flex-start; */
    align-items: center;
    gap: 17px;
    position: relative;
    z-index: 1;
}

.heading {
    color: var(--keel-navy);
    font-size: 40px;
    line-height: 44px;
    margin-bottom: 10px;
    font-weight: 600;
}

.description {
    line-height: 24px;
}