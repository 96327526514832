.hero_container {
    position: relative;
    z-index: 1;
}

.hero_background_image {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    max-width: 100vw;
    object-fit: cover;
    object-position: top center;
    z-index: -2;
}


.hero_background_video {
    position: absolute;
    inset: 0;
    height: 100%;
    width: 100%;
    max-width: 100vw;
    z-index: -1;
    /* opacity: 0; */
    object-fit: cover;
    object-position: top center;
}