.tabbed_accordion {
    display: none;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    width: 100%;
}


.tab {
    width: 100%;
    padding: 16px 20px;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;
    color: var(--keel-navy);
    border-radius: 16px;
    background-color: transparent;
    border: 1px solid var(--keel-navy);
    cursor: pointer;
}

.tab_index {
    font-size: 24px;
    line-height: 24px;
    font-weight: 300;
    box-shadow: transparent 0px 0px 0px;
}

.tab_label {
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
}

.entry_section.active .tab {
    --webkit-backdrop-filter: blur(12px);
    backdrop-filter: blur(12px);
    box-shadow: 25px 25px 50px 0px #002C611A;
    border: 1px solid var(--keel-white);
    background: #FFFFFF66;
}

.entry_section.active .tab_index {
    color: var(--keel-orange);

}

.content {
    width: 100%;
    color: var(--keel-navy);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    padding-top: 20px;
    opacity: 1;
    max-height: max-content;
    /* overflow: hidden; */

    transition: opacity 500ms, padding-top 500ms;

}

.description {
    line-height: 24px;
    white-space: pre-wrap;
}

.content>*:not(.description) {
    margin-inline: 40px;
    /* width: calc(100% - 80px); */
    object-position: center;
    /* filter: drop-shadow(-7px 7px 13.5px #1D32601A); */
}

.entry_section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
}

.entry_section:not(.active) .content {
    /* display: none; */
    /* overflow: hidden; */

    max-height: 0;
    padding-top: 0;
    opacity: 0;
}


@media (max-width: 570px) {

    /* x small */
    .tab_label {
        font-size: 20px;
        line-height: 24px;
    }

    .tab_index {
        font-size: 18px;
        line-height: 20px;
    }

    .tab {
        gap: 10px;
    }

}