#mind {
    position: relative;
}

#hero {
    position: relative;
    max-width: 100vw;

    min-height: 100vh;

    display: flex;
    flex-direction: column;
    padding-top: 180px;
    align-items: center;
    color: var(--keel-white);
    text-align: center;
    background: linear-gradient(0deg, #000911 0%, #032752 65%);

}

#hero h1 {
    margin-bottom: 10px;
    letter-spacing: -2%;
}

#hero_card {
    padding-inline: 46px;
    max-width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

#hero_card_top {
    margin-top: 15px;
}

#hero_subtitle {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
}

#hero_learn_more {
    font-weight: 500;
    padding: 10px 20px;
    margin-top: 45px;
    cursor: pointer;
    border: none;
}

#hero_video_container {
    position: relative;
    margin-top: 70px;
    max-width: 1440px;

    border-radius: 16px;
}

#hero_video_container.show_cover #hero_video {
    visibility: hidden;
}

#hero_video_container:not(.show_cover) #hero_video_cover {
    display: none;
}

#hero_video {
    width: 100%;
    border-radius: 16px;
}

#hero_video_cover {
    position: absolute;
    inset: 0;
}

#hero_video_image_cover {
    width: 100%;
    height: 100%;
    border-radius: 16px;
}

#hero_video_color_cover {
    position: absolute;
    background-color: #002C615C;
    width: 100%;
    height: 100%;
    inset: 0;
    border-radius: 16px;
    /* backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px); */
    cursor: pointer;

}

#hero_video_play_button {
    position: absolute;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    width: 82px;
    height: 82px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    border-radius: 50%;

}

#hero_video_play_button>img {
    width: 100%;
    height: 100%;
}


#mind_content {
    /* position: relative; */
    padding-top: 80px;
    background-color: var(--keel-light-grey);
}

#mind_content>section {
    padding-inline: var(--padding-inline);
}

#mind_content>section:not(.full) {
    max-width: 1440px;
    margin-inline: auto;
}

#mind_content>section.full>* {
    max-width: 1440px;
    margin-inline: auto;
}


#schools_container {
    display: flex;
    gap: 40px;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    /* padding-inline: 46px;
    max-width: 100%; */
    max-width: 1155px;
    padding-block: 100px;
}

#schools_container>img {
    object-fit: contain;
    max-height: 60px;
    max-width: 200px;
}


#mind_content>* {
    position: relative;
    z-index: 1;
}

#building_trust_intro {
    line-height: 24px;
    margin-bottom: 44px;
    text-align: center;
    color: var(--keel-navy);
}

#tool_cards_container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
    justify-content: center;

    margin-bottom: 110px;
}

#our_approach {
    padding-block: 30px 126px;
}

#our_evidence {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 0px;
    margin-bottom: 100px;
}

.evidence_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 160px;
    flex-wrap: wrap;
}

.evidence>strong {
    color: var(--keel-orange);
    font-size: 60px;
    line-height: 60px;
    font-weight: 600;
    text-align: center;
}

.evidence>div {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 5%;
    color: var(--keel-white);
}

#testimonials_section {
    padding-block: 147px 137px;
    background-color: white;
    padding-inline: var(--padding-inline) 0 !important;
}

#faq_section {
    padding-block: 80px 100px;

    background-color: #fff;
}

#our_features {
    padding-bottom: 200px;
}


@media (max-width: 1370px) {
    /* medium */

    #mind_content {
        padding-top: 40px;
    }

    .evidence_container {
        gap: 56px;
        justify-content: space-evenly;
    }

    .evidence strong {
        font-size: 50px;
    }

    #tool_cards_container {
        grid-template-columns: repeat(1, 100%);
        max-width: 700px;
        margin-inline: auto;
    }
}

@media (max-width: 830px) {
    /* small */

    #mind_content {
        padding-top: 40px;

    }

    .evidence_container {
        flex-direction: column;
        align-items: center;
    }

    .government_logos_container {
        flex-direction: column-reverse;
        margin-bottom: 80px;
    }

}