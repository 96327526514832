#labs {
    position: relative;
}

#hero {
    position: relative;
    max-width: 100vw;

    min-height: 100vh;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--keel-white);
    text-align: center;
    padding-block: 180px 35px;
    gap: max(11.5vh, 120px);
    background: linear-gradient(180deg, #001A3A 0%, #002C61 100%);

}

#hero h1 {
    margin-bottom: 10px;
    letter-spacing: -2%;
}

#hero_card {
    padding-inline: 46px;
    max-width: 100%;

    /* padding-block: 150px; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
}

#hero_learn_more {
    font-weight: 500;
    padding: 10px 20px;
    margin-top: 45px;
    cursor: pointer;
    border: none;
}

#hero_card_top {
    margin-top: 15px;
}


#hero_subtitle {
    line-height: 24px;
    font-weight: 300;
    padding-inline: var(--padding-inline);
    max-width: calc(909px + 2 * var(--padding-inline));
}


#labs_content {
    /* position: relative; */
    padding-top: 95px;
    background-color: var(--keel-light-grey);
}

#labs_content>section {
    padding-inline: var(--padding-inline);
}

#labs_content>section:not(.full) {
    max-width: 1440px;
    margin-inline: auto;
}

#labs_content>section.full>* {
    max-width: 1440px;
    margin-inline: auto;
}

#what_we_do {
    /* display: grid;
    grid-template-columns: 1fr 640px;
    gap: 48px; */

    display: flex;
    flex-direction: column;
    gap: 100px;
}

#what_we_do_content {
    display: flex;
    flex-direction: column;
    gap: 0px;
    gap: 100px;
}

#labs_solution_graphic {
    max-width: 640px;
    width: 50%;
    margin-block: auto;
}

#work_with {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 0px;
    margin-top: 140px;
    padding-bottom: 100px;
}

#work_with_logos_container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    justify-items: center;
    gap: 40px;
    padding-inline: 20px;
}

#work_with_logos_container img {
    max-width: 100%;
}

#faq_section {
    padding-block: 80px 100px;
    background-color: white;
}

@media (max-width: 1370px) {
    /* medium */

    #labs_content {
        padding-top: 100px;
    }

}

@media (max-width: 830px) {
    /* small */

    #labs_content {
        padding-top: 80px;

    }

    /* 
    #what_we_do {
        display: grid;
        grid-template-columns: 1fr; 
    }
    */

    #labs_solution_graphic {
        width: 100%;
    }

    .align_right_small {
        object-position: right;
    }

    .align_left_small {
        object-position: left;
    }

}

@media (max-width: 570px) {
    /* x-small */

    #work_with_logos_container {
        grid-template-columns: 1fr;
    }
}