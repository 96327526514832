.header_dropdown_container {
    position: relative;
    z-index: 1;
}

.header_dropdown {
    stroke: var(--keel-white);
    stroke-width: 4;
    cursor: pointer;
}

.arrow {
    width: 10px;
}

.children_container_buffer {
    position: absolute;
    padding-top: 4px;
    top: 100%;
    left: 50%;
    translate: -50%;
    pointer-events: none;
}

.children_container_buffer.show {
    pointer-events: all;
}

.children_container {
    padding: 14px;
    opacity: 0;
    pointer-events: none;

    display: flex;
    flex-direction: column;
    gap: 10px;

    border-radius: 16px;
    background-color: #ffffff80;
    box-shadow: rgba(0, 18, 56, 0.16) 0px 10px 24px;

    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);

    transition: opacity 0.2s ease-out, margin-top 0.2s ease-out;
}

.children_container_buffer.show>.children_container {
    opacity: 1;
    pointer-events: all;
}