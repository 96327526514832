#header {
    position: absolute;
    z-index: 20;
    width: 100%;
    height: 80px;
    padding: 15px 40px;

    /* display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; */

    display: grid;
    grid-template-columns: 225px 1fr 225px;
    gap: 20px;
}

#header>* {
    height: 100%;
}

#middle {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 30px;
    justify-self: center;
    /* margin-left: 43px; */
}

#right {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;

    justify-self: end;
}

#keel_logo {
    height: 50px;
    object-fit: contain;
    object-position: center;
}

.header_button {
    font-size: 12px;
    line-height: 20px;
    padding: 10px 20px;
    margin-block: 5px;
}

#contact_us {
    font-weight: 500;
}

#sign_in {
    font-size: 12px;
}

.navlink {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: var(--keel-white);
    text-decoration: none;
    width: max-content;
}

.dark_text {
    color: var(--keel-navy);
    border-color: var(--keel-navy);
    stroke: var(--keel-navy) !important;
}

@media (max-width: 1370px) {
    /* medium */

    #header {
        padding: 15px 30px;
    }

}

#hamburger_button {
    display: none;
    width: 40px;
    height: 40px;
    cursor: pointer;
    margin-top: 10px;
}

#hamburger_button>img {
    pointer-events: none;
    width: 100%;
    height: 100%;
}

@media (max-width: 770px) {
    /* small */

    #header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    #middle {
        display: none;
    }

    #right {
        display: none;
    }

    #hamburger_button {
        display: flex;
    }
}