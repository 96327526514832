#hamburger_menu {
    position: fixed;
    z-index: 10;
    inset: 0;
    width: 100vw;
    height: 100vh;
    pointer-events: none;
    opacity: 0;
    padding: 15px 30px;
    background-color: var(--keel-navy);

    transition: 200ms ease opacity;
}

#hamburger_menu.show {
    opacity: 100%;
    pointer-events: all;
}

#top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 60px;
}

#close_button {
    cursor: pointer;
    margin-top: 10px;
}

#keel_logo {
    object-fit: contain;
    height: 50px;
    object-position: center;
}

#links_container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 30px;
    margin-top: 50px;
}

.link {
    font-size: 28px;
    line-height: 32px;

    color: var(--keel-white);
    font-weight: 500;
    width: max-content;
    text-decoration: none;
}

#bottom_links {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 30px;
    margin-top: 34px;
}

@media (min-width: 771px) {
    #hamburger_menu {
        display: none;
    }
}