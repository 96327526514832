#footer {
    padding: 60px 170px 60px 140px;
    width: 100%;
    max-width: 1440px;
    margin-inline: auto;
    /* max-width: calc(1440px - 310px); */
    color: var(--keel-navy);
}

#footer_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 32px;
    width: 100%;
}

#footer_wrapper>* {
    width: 100%;

}

#top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 100px;
    border-bottom: 1px solid var(--keel-navy);
    padding-bottom: 120px;
}

#top_right {
    /* grid-template-columns: repeat(5, 1fr); */
    /* width: 100%; */
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 100px;
}

.link_list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
}

#footer a {
    color: var(--keel-navy);
    text-decoration: none;
    line-height: 24px;
}

#footer strong {
    color: var(--keel-navy);
}

#keel_logo {
    width: 138px;
    object-fit: contain;
}

#bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 0px;
}

#bottom_middle {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    gap: 40px;
}

#bottom_right {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
}

@media (max-width: 1370px) {

    /* medium */
    #footer {
        padding: 30px 85px 40px 70px;
    }

    #top {
        gap: 50px;
    }

    #top_right {
        gap: calc(10vw - 37px);
        /* 100px @ 1370, 50px @ 870 */
    }

    #keel_logo {
        width: 100px;
    }
}

@media (max-width: 830px) {
    /* small */

    #bottom {
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }

    #bottom_middle {
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }

    #top {
        flex-direction: column;
        align-items: center;
    }

    #top_right {
        gap: 50px;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: flex-start;
        flex-wrap: wrap;
    }
}

@media (max-width: 570px) {
    /* x small */

    #top_right {
        display: grid;
        justify-items: center;
        grid-template-columns: 1fr;
    }

    .link_list {
        align-items: center;
    }
}