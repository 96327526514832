.main_wrapper {
    background-color: var(--keel-light-grey);
}

#body {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;

    padding-bottom: 200px;
}