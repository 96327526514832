.hamburger_dropdown_container {
    width: 100%;
}

.hamburger_dropdown {
    background-color: transparent;
    border: none;
    cursor: pointer;
    stroke: var(--keel-white);
    stroke-width: 3;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    /* justify-content: space-between;
    padding-right: 20px; */
}

.arrow {
    width: 20px;
}

.children_container {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    padding-left: 20px;
    padding-top: 20px;

    opacity: 0;
    pointer-events: none;

    transition: opacity ease-out 0.2s;
}

.children_container.show {
    position: relative;
    pointer-events: all;
    opacity: 1;
    height: max-content;
}