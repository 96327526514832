#main {
    position: relative;
}

#main>section {
    background-color: var(--keel-light-grey);

}

#scroll_container {
    --extra-scroll-height: 200vh;
    position: relative;
    min-height: 100vh;
    padding-block: 160px 100px;

    max-width: 100vw;

    display: flex;
    flex-direction: column;
    color: var(--keel-white);


    height: calc(100vh + var(--extra-scroll-height));
}

#background_hero_video {
    --top: 0px;
    position: fixed;
    top: var(--top);
    width: 100vw;
    min-height: 100vh;
    z-index: -1;
}

#hero_top {
    padding-inline: var(--padding-inline);
    color: var(--keel-white);
}

#hero_text {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
}

#hero_text h1 {
    margin-bottom: 11px;
}

#hero_text h1 em {
    font-weight: 300;
}

#hero_text p {
    line-height: 20px;
}

.quote_card {
    background-color: var(--keel-orange);
    margin-bottom: 140px;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 30px;
    color: white;
}

.quote_text {
    font-size: 32px;
    line-height: 40px;
    font-weight: 600;
}

#quote_section {
    padding-inline: var(--padding-inline);
    display: flex;
    justify-content: center;
    width: 100%;
}

.quote_source {
    display: flex;
    flex-direction: row;
    gap: 18px;
    font-weight: 600;
    line-height: 24px;
    font-size: 20px;
}

.quote_source_title {
    font-weight: 400;
    font-size: 16px;
}

.quote_source_image {
    width: 52px;
    height: 52px;
    object-fit: contain;
}

.open_quote {
    margin-inline-end: 48px
}

.close_quote {
    margin-inline-start: 48px
}

.advisors_section {
    padding-block: 120px;
    padding-inline: var(--padding-inline);
}

#board_section {
    padding-bottom: 0;
}


.advisors_container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 23px 40px;
    /* width: fit-content; */
    justify-items: center;
    max-width: 1440px;
    margin-inline: auto;
}

#leaders_overflow_wrapper {
    overflow: hidden;
    max-width: 100vw;
    margin-top: 100px;
    padding-inline: var(--padding-inline);
    padding-bottom: 40px;

    position: sticky;
    top: 0;

}

#leaders_container {
    --translation: 0px;
    display: grid;
    grid-template-columns: repeat(5, 360px);
    overflow: visible;
    gap: 0px;
    max-width: 100vw;
    /* padding-inline-end: 1360px; */

    translate: calc(50% - var(--translation));

    /* -ms-overflow-style: none;
    scrollbar-width: none; */
}

.leaders_container::-webkit-scrollbar {
    display: none;
}


@media (max-width: 1370px) {

    /* medium */
    #hero_top {
        gap: 40px;
    }
}

@media (max-width: 830px) {

    /* small */
    #hero_top {
        grid-template-columns: 1fr;
        justify-items: center;
    }

    #hero_text {
        /* text-align: center; */
        gap: 20px;
    }

    #hero_text>p {
        max-width: none;
    }

    #our_advisors {
        padding-block: 80px 120px;
    }

    .advisors_container {
        grid-template-columns: 1fr;
        width: 100%;
        justify-items: center;
    }
}

/* 
@media (max-width: 570px) {

#leaders_container {
    --col-width: min(360px, calc(100vw - var(--padding-inline) * 2 - 40px));

    grid-template-columns: repeat(5, var(--col-width));
}
}

*/