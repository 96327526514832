.leader_card_wrapper {
    width: 100%;
    height: 100%;
    translate: -50%;

}

.leader_card {
    width: 100%;
    height: 100%;
    padding: 24px;
    padding-bottom: 20px;
    background-color: rgba(255, 255, 255, 0.1);
    /* max-width: min(360px, calc(360px - 20px)); */
    /* max-width: calc(100vw - var(--padding-inline) * 2); */

    backdrop-filter: blur(28px);
    box-shadow: 25px 25px 50px 0px rgba(0, 44, 97, 0.1);
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    color: var(--keel-white);

    filter: blur(6px);
    scale: 0.85;
    transition: all 500ms ease;
}

.leader_card_wrapper.focused>.leader_card {
    filter: blur(0px);
    scale: 1;
}

.leader_card:hover {
    border: 1px solid rgba(255, 255, 255, 0.65);
}

.front {
    display: flex;
    flex-direction: column;
    gap: 24px;
    height: 100%;
    cursor: pointer;
}

.big_headshot_container {
    position: relative;
    max-width: 312px;
    max-height: 312px;

    width: 100%;
    height: 100%;
    aspect-ratio: 1 / 1;
}

.headshot_overlay {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 16px;
    background-color: hsla(216, 67%, 42%, 0.25);
    mix-blend-mode: color;
    z-index: 2;
    width: 100%;
    height: 100%;
}

.big_headshot {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top center;
    border-radius: 16px;
    aspect-ratio: 1 / 1;

}

.linkedin_link {
    width: 22px;
    height: 22px;
}

.details {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.name {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
}

.title {
    color: var(--keel-orange);
    font-weight: 500;

}

.read_more {
    margin-top: auto;
    cursor: pointer;
}

.back {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    gap: 20px;
}

.back_top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.small_headshot {
    max-width: 140px;
    max-height: 140px;
    aspect-ratio: 1/1;
    width: 100%;
    object-fit: cover;
    object-position: top center;
    border-radius: 16px;
}

.small_headshot_container {
    position: relative;
    max-width: 140px;
    max-height: 140px;

    width: 100%;
    height: 100%;
    aspect-ratio: 1 / 1;
}

.close_button {
    width: 40px;
    height: 40px;
    padding: 13px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.2);

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
}

.back_middle {
    margin-top: 4px;
}

.back_middle .title {
    font-weight: 400;
}


@media (max-width: 570px) {

    .big_headshot_container {
        /* align-self: center; */
    }

}