#main {
    background-color: var(--keel-light-grey);
}

#email_link {
    font-weight: 500;
}

#contact_section {
    padding: 160px var(--padding-inline) 140px var(--padding-inline);

    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 80px;
    max-width: 1440px;
    margin: auto;

}

.right {
    width: 100%;
    padding: 72px 62px;
    border-radius: 16px;
    border: 2px solid white;
    background-color: rgba(255, 255, 255, 0.5);
    box-shadow: #002C611A 25px 25px 50px;
    min-height: 600px;
}



.left {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 60px;
}

.top {
    color: var(--keel-navy);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
}

/* .top h1 {
    font-weight: 600;
    font-size: 60px;
    line-height: 68px;
} */

.top p {
    line-height: 24px;
}

.bottom {
    color: var(--keel-navy);
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 44px;
}

.address {
    line-height: 24px;
}

@media (max-width: 1370px) {
    .right {
        padding: 50px 40px;
    }

    #contact_section {
        gap: 40px;
    }

}

@media (max-width: 830px) {
    #contact_section {
        grid-template-columns: 1fr;
    }
}