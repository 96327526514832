.timeline_outer {
    --bottom-buffer: 10vh;
    --top-buffer: 10vh;
    --full-progress-bar-height: 50vh;

    padding-bottom: var(--bottom-buffer);
    padding-top: var(--top-buffer);
    position: relative;
    scroll-behavior: smooth;
    isolation: isolate;
}

.progress_bar {
    width: 4px;
    left: 50%;
    translate: -50%;
}

.empty_progress_bar {
    height: 100%;
    background-color: #edf2fb;
    position: absolute;
    z-index: -2;
    top: 0;
}

.full_progress_bar {
    position: fixed;
    top: 0;
    background-color: #c4c9d4;
    left: calc(75vw - var(--padding-inline) / 2);
    z-index: 1;
    height: var(--full-progress-bar-height);
}

.progress_bar_cover {
    position: absolute;
    z-index: 2;
}

.progress_bar_top_cover {
    top: 0;
    height: var(--top-buffer);
    background-image: linear-gradient(to bottom, white 0%, transparent 90%);
}


.progress_bar_bottom_cover {
    bottom: 0;
    height: var(--bottom-buffer);
    background-image: linear-gradient(to bottom, #edf2fb 0%, white 90%);
}


.timeline_container {
    position: relative;
    z-index: 3;
    display: grid;
    grid-template-columns: 1fr 20px 1fr;
    column-gap: 40px;
    row-gap: 120px;
}

.data_node {
    display: flex;
    flex-direction: column;
    gap: 8px;
    color: var(--keel-navy);
}

.timeline_container>* {
    min-width: 0;
}

.data_node:last-of-type {
    /* padding-bottom: max(112px, 10vh); */
}

.data_node .year {
    background-color: #ff640724;
    color: var(--keel-orange);
    font-weight: 500;
    padding: 6px 10px;
    border-radius: 20px;
    width: max-content;
}

.data_node .title {
    font-weight: 500;
    font-size: 30px;
    /* line-height: 24px; */
}

.data_node .body {
    color: #002C6180;
    font-size: 18px;
    /* line-height: 18px; */
}

.middle_node {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    position: relative;
}

.middle_node .circle {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #c4c9d4;
    position: sticky;
    top: var(--full-progress-bar-height);
    border: 4px solid white;
}

.middle_node .line {
    width: 4px;
    flex-grow: 1;
    /* height: 100%; */
    background-color: #1D31604A;
}

#first_line {
    height: var(--bottom-buffer);
    background-color: transparent;
}

@media (max-width: 830px) {
    .data_node .title {
        font-size: 20px;
        line-height: 24px;
    }

    .data_node .body {
        font-size: 14px;
        line-height: 18px;
    }

    .timeline_container {
        column-gap: 20px;
        grid-template-columns: 1fr 10px 1fr;
    }
}


@media (max-width: 570px) {
    .full_progress_bar {
        left: 50%;
    }
}