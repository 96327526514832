#home {
    position: relative;
}

#hero {
    position: relative;
    max-width: 100vw;

    min-height: 100vh;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--keel-white);
    text-align: center;
    padding-block: 180px 95px;
    gap: max(11.5vh, 120px);
}

#hero h1 {
    font-size: 82px;
    line-height: 86px;
    font-weight: 600;
    margin-bottom: 20px;
    letter-spacing: -2%;
}

#hero_card {
    padding-inline: 46px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 100px;
}

#hero_card_top {
    margin-top: 15px;
}

#hero_subtitle {
    font-size: 20px;
    line-height: 24px;
    font-weight: 300;
}

#hero_images_overflow_container {
    overflow: hidden;
    max-width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* margin-top: 110px; */

    display: none;
}


#hero_images {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    min-width: 133vw;
}

#hero_images img {
    width: 100%;
    min-height: 300px;
    max-height: 500px;
    object-fit: cover;
    border-radius: 16px;
}

#home_content {
    /* position: relative; */
    padding-block: 80px 100px;
    background-color: var(--keel-light-grey);
}

#home_content>section {
    padding-inline: var(--padding-inline);
}

#home_content>section:not(.full) {
    max-width: 1440px;
    margin-inline: auto;
}

#home_content>section.full>* {
    max-width: 1440px;
    margin-inline: auto;
}

#our_solutions {
    padding-top: 70px;
}

.solutions_container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 60px;
    margin-bottom: 130px;
}

.government_logos_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 28px;
    height: 40px;
    margin-block: 80px 65px;
}

.government_logos_container>img {
    height: 100%;
}

#home_content>* {
    position: relative;
    z-index: 1;
}

#home_plexus {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 0;
    height: 71vw;
    transform: translateY(-50%);
    /* background-color: red; */
    background-image: url(../../assets/backgrounds/home-plexus.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}


#building_trust_intro {
    line-height: 24px;
    margin-bottom: 44px;
    text-align: center;
    color: var(--keel-navy);
}

#tool_cards_container {
    display: flex;
    flex-direction: row;
    gap: 40px;
    justify-content: space-between;

    margin-bottom: 110px;
}

#our_evidence {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 0px;
    margin-block: 140px 100px;
}

.evidence_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 160px;
    flex-wrap: wrap;
}

.evidence>strong {
    color: var(--keel-orange);
    font-size: 60px;
    line-height: 60px;
    font-weight: 600;
    text-align: center;
}

.evidence>p {
    font-size: 14px;
    line-height: 24px;
    font-weight: 300;
    letter-spacing: 5%;
    color: var(--keel-white);
}


@media (max-width: 1370px) {
    /* medium */

    #hero h1 {
        font-size: 60px;
        line-height: 68px;
    }

    #home_content {
        padding-block: 40px 50px;
    }

    .evidence_container {
        gap: 56px;
        justify-content: space-evenly;
    }

    .evidence strong {
        font-size: 50px;
    }
}

@media (max-width: 830px) {
    /* small */

    #home_content {
        padding-block: 40px 30px;

    }

    .evidence_container {
        flex-direction: column;
        align-items: center;
    }

    #tool_cards_container {
        flex-direction: column;
        align-items: center;
    }

    .government_logos_container {
        flex-direction: column-reverse;
        margin-bottom: 80px;
    }

}

@media (max-width: 570px) {
    #hero_images {
        grid-template-columns: repeat(2, 1fr);
    }

    #hero_images img {
        min-height: 100px;
        max-height: 200px;
    }

    #hero h1 {
        font-size: 54px;
        line-height: 64px;
    }
}