.tool_card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 10px;

    padding: 30px;
    border: 1px solid white;
    border-radius: 16px;
    color: var(--keel-navy);

    width: 100%;
    height: 100%;

    background-color: #FFFFFF66;


    backdrop-filter: blur(12px);
    box-shadow: 25px 25px 50px 0px #002C611A;
}

.tool_card>img {
    width: 80px;
    height: 80px;
    margin-bottom: 26px;
    object-fit: contain;
}

.bottom {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.bottom strong {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
}

.bottom p {
    line-height: 24px;
}