.main {
    min-height: 800px;
}

#heading {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 0px;
}

#tiles_container {
    display: grid;
    grid-template-columns: repeat(auto-fit, 250px);
    justify-content: center;

    gap: 20px;

    width: 100%;
}