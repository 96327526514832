.container>picture>img {
    max-width: 100%;
}

.container {
    position: relative;
    max-width: max-content;
}

.overlay_img {
    position: absolute;
    opacity: 0;
    top: var(--top);
    bottom: var(--bottom);
    left: var(--left);
    right: var(--right);
    translate: var(--translate);
    max-width: var(--maxWidth);
    /* transition: inset ease 200ms; */
}

@media (max-width: 1370px) {

    .overlay_img {
        top: var(--mediumTop);
        bottom: var(--mediumBottom);
        left: var(--mediumLeft);
        right: var(--mediumRight);
        max-width: var(--mediumMaxWidth);
        translate: var(--mediumTranslate);
    }
}

/* @media (max-width: 830px) {
    .overlay_img {
        top: var(--smallTop);
        bottom: var(--smallBottom);
        left: var(--smallLeft);
        right: var(--smallRight);
        max-width: var(--smallMaxWidth);
        translate: var(--smallTranslate);
    }
} */